import React, { Component, useEffect, useState } from 'react';
import ExamForm from '../Exams/ExamForm';
import Loader from '../Loader/Loader';
import { auth, storage } from '../Auth/firebase';

import addIcon from '../../assets/images/upload.png';
import { API } from '../../Constants';



const Assignment = (props) => {
    const [assignment, setAssignment] = useState({});
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState("");

    useEffect(() => {
        let { id } = props.match.params

        let uid = window.localStorage.getItem('uid')
        let token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }

        // TODO: get lecture from API call instead
        fetch(`${API}/lecture/only/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            }
        }).then(Response => Response.json()).then(res => {
            if (res.accept) {
                // console.log("assignment: ", res.data)
                setAssignment(res.data);
                setLoading(false);
            } else {
                alert('It seems your data is missing, please login again')
            }
        })
    })

    const checkFile = (file) => {
        if (file?.size > 20971520) {
            alert('The file you selected exceeds 20 MBs');
            return;
        } else if (!file?.name.match(/^.*\.pdf$/)) {
            alert('The file you selected is not a PDF file');
            return;
        }
        setFile(file);
    }

    // uploading the file to database
    // const uploadFile = async (uri) => {
    //     let user = auth.currentUser

    //     try {
    //         let assigmentRef = storage.ref("Submissions").child(`${user.uid}_${assignment.name}_${Date.now()}.pdf`);
    //         await assigmentRef.put(uri);

    //         // get the download link
    //         let url = await assigmentRef.getDownloadURL();
    //         return url;
    //     } catch (e) {
    //         // console.log(e);
    //     }
    // }

    const submitAssingment = async () => {
        // console.log("submitting exam...");

        setLoading(true);

        let { id } = props.match.params

        let uid = window.localStorage.getItem('uid')
        let token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }

        // let url = await uploadFile(file)

        const data = new FormData()
        data.append('file', file)
        fetch(`${API}/lecture/${id}/upload`, {
            method: "POST",
            headers: {
                'auth-token': token
            },
            body: data
        }).then(Response => Response.json()).then(res => {
            // console.log('answers res: ', res)
            if (res.accept) {
                alert(`Your submission has been saved and under review`)
                window.location.pathname = '/'
                setLoading(false);
            } else {
                alert(`${res.message || `An unknown error occured`}`)
                window.location.pathname = '/lectures'
                setLoading(false);
            }
        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    if (loading) {
        return (
            <div className="dashboard-wrapper assignment-wrapper">
                <Loader />
            </div>
        )
    }

    return (
        <div className="dashboard-wrapper assignment-wrapper">
            <div className="lecture-title-container assignment">
                <h1>{assignment.name}</h1>
            </div>

            {/* <ExamForm isAssignment={true} exam={assignment} changeAnswer={this.changeAnswer} submitExam={this.submitAssignment} /> */}
            <div className='assignment-upload-button-wrapper'>
                <label htmlFor="input-file-upload" className="done-button add-file-button"> <img src={addIcon} alt="add" /> Upload <span>(max 20 MBs)</span> </label>
                <input id="input-file-upload" type="file" accept="application/pdf" style={{ display: "none" }} onChange={(e) => checkFile(e.target.files[0])} />
                <span className='file-name'>{file.name}</span>
            </div>
            <button className="done-button submit-button" onClick={() => submitAssingment()}>Submit</button>
        </div>
    );
}
 
export default Assignment;