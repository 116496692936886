import React, { Component, Profiler } from 'react';
import Loader from '../Loader/Loader';

import monitorIcon from '../../assets/images/video.svg';
import taskIcon from '../../assets/images/exam.png';
import nextIcon from '../../assets/images/next.svg';
import doneIcon from '../../assets/images/done.png';
import pendingIcon from '../../assets/images/archive.png';
import blockedIcon from '../../assets/images/blocked.png';

import './Lectures.css';

class LecturesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosen: "lectures",
            loading: false,
            chapters: [],
            selectedChapter: {},
            selectedChapterAss: {},
        }
    }

    componentDidMount() {
        const { syncProfile } = this.props

        const uid = window.localStorage.getItem('uid')
        const token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }
        syncProfile().then(Response => Response.json()).then(res => {
            if (res.accept) {
                // console.log('user res: ', res)
                const { chapters } = res.data;
                this.setState({...this.state, chapters})
            } else {
                alert(`${res.message || `An unknown error occured`}`)
            }
        }).catch(err => {
            // console.log(err)
        });
    }

    changeSelectedChapter = (name, chapter) => {
        let state = { ...this.state }

        let selected = state[name]

        if (selected[chapter]) {
            selected[chapter] = false
        } else {
            selected[chapter] = true
        }

        this.setState(state)
    }

    renderLectures = () => {
        let { selectedChapter, chapters } = this.state;
        // console.log("lectures chapters: ", chapters)

        if (!chapters) return <h1 className="lectures-warning">No Chapters yet</h1>

        let render = Object.keys(chapters).map((chapterId, idx) => {
            const chapter = chapters[chapterId];
            const lectures = chapter.lectures;

            let res = lectures && Object.keys(lectures).length ? (
                Object.keys(lectures).sort((lecIdA, lecIdB) => {
                    const lectureA = lectures[lecIdA];
                    const lectureB = lectures[lecIdB];
                    return lectureA.number - lectureB.number;
                }).map((lectureId, idx2) => {
                    const lecture = lectures[lectureId];
                    let [style, image, url] = ["", monitorIcon, `/lecture/${lectureId}`]
    
                    return (
                        <div key={idx2} className={"lectures-item " + style} onClick={() => this.props.history.push(url)}>
                            <img src={image} alt="monitor" />
                            <h2>{lecture.name}</h2>
                            <img src={nextIcon} alt="next" />
                        </div>
                    )
                })
            ) : (
                <h1 className="lectures-warning">No lectures yet</h1>
            )

            return (
                <div key={idx} className={"chapter-wrapper " + (selectedChapter[chapter.name] ? "show-chapter" : "")}>
                    <h1
                        className="chapter-name"
                        onClick={() => this.changeSelectedChapter("selectedChapter", chapter.name)}
                    >
                        {chapter.name}
                        <img className="chapter-drop-icon" src={nextIcon} alt="drop" />
                    </h1>
                    <div className="lectures-drop-wrapper">
                        {res}
                    </div>
                </div>
            )
        })

        return render
    }

    renderAssignments = () => {
        const { selectedChapterAss, chapters } = this.state;
        // console.log("lectures chapters: ", chapters)

        if (!chapters) return <h1 className="lectures-warning">No Chapters yet</h1>

        const render = Object.keys(chapters).map((chapterId, idx) => {
            const chapter = chapters[chapterId];
            const lectures = chapter.lectures;

            let res = lectures && Object.keys(lectures).length ? (
                Object.keys(lectures).sort((lecIdA, lecIdB) => {
                    const lectureA = lectures[lecIdA];
                    const lectureB = lectures[lecIdB];
                    return lectureA.number - lectureB.number;
                }).map((lectureId, idx) => {
                    const lecture = lectures[lectureId];
                    let [style, image, url] = !lecture.isHWRequired ? ["disabled", blockedIcon, ``] : !lecture.approved && !lecture.submitted ? ["", taskIcon, `/assignment/${lectureId}`] : lecture.approved ? ["disabled", doneIcon, ``] : ["disabled", pendingIcon, ``]
    
                    return (
                        <div key={idx} className={"lectures-item " + style} onClick={style === 'disabled' ? undefined : () => this.props.history.push(url)}>
                            <img src={image} alt="monitor" />
                            <h2>{lecture.name}</h2>
                            {style !== 'disabled' && <img src={nextIcon} alt="next" />}
                            {style === 'disabled' && <h3 className={!lecture.isHWRequired ? 'blocked' : lecture.approved ? 'approved' : 'pending'}>{!lecture.isHWRequired ? 'HW not required' : lecture.approved ? 'Approved' : 'Pending'}</h3>}
                        </div>
                    )
                })
            ) : (
                <h1 className="lectures-warning">No assignments yet</h1>
            )

            return (
                <div key={idx} className={"chapter-wrapper " + (selectedChapterAss[chapter.name] ? "show-chapter" : "")}>
                    <h1
                        className="chapter-name"
                        onClick={() => this.changeSelectedChapter("selectedChapterAss", chapter.name)}
                    >
                        {chapter.name}
                        <img className="chapter-drop-icon" src={nextIcon} alt="drop" />
                    </h1>
                    <div className="lectures-drop-wrapper">
                        {res}
                    </div>
                </div>
            )
        })

        return render


        /*let { assignments } = this.state;
        let res = assignments && assignments.length ? (
            assignments.map((assignment, idx) => {
                let [style, image, url] = assignment.isHidden ? ["disabled", taskIcon, ""] : ["", taskIcon, `/assignment/${assignment.lecID}`]

                return (
                    <div key={idx} className={"lectures-item " + style} onClick={() => this.props.history.push(url)}>
                        <img src={image} alt="monitor" />
                        <h2>{assignment.name + ` (${assignment.chapter || ""})`}</h2>
                        <img src={nextIcon} alt="next" />
                    </div>
                )
            })
        ) : (
            <h1 className="lectures-warning">No assignments yet</h1>
        )

        return res;*/
    }

    chooseSection = (chosen) => {
        this.setState({ chosen });
    }

    render() {
        let { loading, chosen } = this.state;

        if (loading) {
            return (
                <div className="dashboard-wrapper">
                    <Loader />
                </div>
            )
        }
        
        return (
            <div className="dashboard-wrapper">
                <div className="lectures-choose">
                    <h3 className={chosen === "lectures" ? "active" : ""} onClick={() => this.chooseSection("lectures")}>Lectures</h3>
                    <h3 className={chosen === "assignments" ? "active" : ""} onClick={() => this.chooseSection("assignments")}>Assignments</h3>
                </div>

                <div className={"lectures-wrapper " + (chosen === "lectures" && "show")}>
                    <h1>Lectures</h1>
                    {this.renderLectures()}
                </div>

                <div className={"lectures-wrapper " + (chosen === "assignments" && "show")}>
                    <h1>Homework uplaod</h1>
                    {this.renderAssignments()}
                </div>
            </div>
        );
    }
}
 
export default LecturesPage;