import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { auth } from '../Auth/firebase'

import logo from '../../assets/images/gold.png';
import logoName from '../../assets/images/centered.png';
import menu from '../../assets/images/menu.svg';
import close from '../../assets/images/close.svg';

import './Navbar.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobile: "",
        }
    }

    logOut = () => {
        auth.signOut();
        window.localStorage.removeItem('uid')
        window.localStorage.removeItem('token')
        this.props.history.push('/login')
    }

    toggleMenu = () => {
        let { showMobile } = this.state

        if (showMobile === "") {
            showMobile = "show"
        } else {
            showMobile = ""
        }

        this.setState({ showMobile })
    }

    render() {
        let { showMobile } = this.state

        return (
            <div className="navbar-wrapper">
                <img className="navbar-mobile-menu" src={menu} alt="menu" onClick={this.toggleMenu} />
                
                <NavLink className="navbar-brand" exact to="/">
                    <img className="brand-image" src={logo} alt="ElMahdy" />
                    <img className="brand-name" src={logoName} alt="ElMahdy" />
                </NavLink>

                <div className="navbar-links">
                    <NavLink exact to="/lectures">Lectures & Homework</NavLink>
                    <NavLink exact to="/exams">Exams</NavLink>
                    <NavLink exact to="/">Dashboard</NavLink>
                    <NavLink exact to="/announcements">Announcements</NavLink>
                    <button className="done-button logout-button" onClick={this.logOut}>Logout</button>
                </div>

                <div className={"navbar-mobile-wrapper " + showMobile}>
                    <div className="navbar-mobile-container navbar-links">
                        <img src={close} alt="close" onClick={this.toggleMenu} />
                        
                        <img className="brand-image" src={logo} alt="ElMahdy" />
                        
                        <NavLink exact to="/lectures" onClick={this.toggleMenu}>Lectures & Homework</NavLink>
                        <NavLink exact to="/exams" onClick={this.toggleMenu}>Exams</NavLink>
                        <NavLink exact to="/" onClick={this.toggleMenu}>Dashboard</NavLink>
                        <NavLink exact to="/announcements" onClick={this.toggleMenu}>Announcements</NavLink>
                        <button className="done-button logout-button" onClick={() =>  {this.toggleMenu(); this.logOut()}}>Logout</button>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withRouter(Navbar);