import React, { Component } from "react";
import { auth } from "../Auth/firebase";

import "./Announcements.css";
import Loader from "../Loader/Loader";
import { API } from "../../Constants";

;

class Announcements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	componentDidMount() {
		let uid = window.localStorage.getItem("uid");
		let token = window.localStorage.getItem("token");

		if (!uid || !token) {
			window.location.pathname = "/login";
			return;
		}

		let user = auth.currentUser;

		fetch(`${API}/announcement/`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"auth-token": token,
			},
		})
			.then((Response) => Response.json())
			.then((res) => {
				if (res.accept) {
					console.log('announcements res: ', res)
					let announcements = res.data;

					this.setState({ announcements, uid, loading: false });
				} else {
					alert(`${res.message || `An unknown error occured`}`);
					this.props.history.push("/login");
				}
			})
			.catch((err) => {
				// console.log(err)
				alert("Error please check your connection and reload");
			});
    }
    
    renderAnnouncements = () => {
        let { announcements } = this.state

        return announcements && announcements.length ? (
            announcements.map((announcement, idx) => {
                return (
                    <div key={idx} className="announcement-item">
                        <h1>{announcement.head}</h1>
                        <p>{announcement.body}</p>
                        <div className="video-iframe" dangerouslySetInnerHTML={{ __html: announcement.link }} />
                        <h2>{new Date(announcement.date).toLocaleString()}</h2>
                    </div>
                )
            })
        ) : (
            <h2 className="lectures-warning">No announcements yet</h2>
        )
    }

	render() {
		let { loading } = this.state;

		let { profile, uid } = this.props;

		if (loading) {
			return (
				<div className="dashboard-wrapper">
					<Loader />
				</div>
			);
		}

		return (
			<div className="dashboard-wrapper">
                <div className="announcement-wrapper">
                    {this.renderAnnouncements()}
                </div>
            </div>
		);
	}
}

export default Announcements;
