import React, { Component } from "react";
import { auth } from "../Auth/firebase";
import QRCode from "qrcode.react";

//import qr from '../../assets/images/qr.png';
import monitorIcon from "../../assets/images/video.svg";
import checkIcon from "../../assets/images/tick-green.svg";
import errorIcon from "../../assets/images/error.svg";
import infoIcon from "../../assets/images/information.png";
import profile from "../../assets/images/man.svg";

import "./Dashboard.css";
import Loader from "../Loader/Loader";
import { API } from "../../Constants";

;

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	componentDidMount() {
		let uid = window.localStorage.getItem("uid");
		let token = window.localStorage.getItem("token");

		if (!uid || !token) {
			window.location.pathname = "/login";
			return;
		}

		let user = auth.currentUser;

		fetch(`${API}/user/${user.uid}/score`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"auth-token": token,
			},
		})
			.then((Response) => Response.json())
			.then((res) => {
				if (res.accept) {
					// console.log('exams notes res: ', res)
					let exams = res.data;

					this.setState({ exams, uid, loading: false });
				} else {
					alert(`${res.message || `An unknown error occured`}`);
					this.props.history.push("/login");
				}
			})
			.catch((err) => {
				// console.log(err)
				alert("Error please check your connection and reload");
			});
	}

	renderExams = () => {
		let { exams } = this.state;

		if (!exams) return "";

		exams.sort((a, b) => {
			if (a.name > b.name) {
				return 1
			  } else if (a.name < b.name) {
				return -1
			  } else {
				return 0
			  }
		})

		let res = exams.length ? (
			exams.map((exam, idx) => {
				if (!exam) return "";
				return (
					<div key={idx} className="dashboard-table-item">
						<h3>{exam.name}</h3>
						<h3
							className={
								!exam.passed ? "fail-text" : "success-text"
							}
						>
							{exam.score}
						</h3>
						<h3>{exam.notes || "No notes"}</h3>
					</div>
				);
			})
		) : (
			<h2 className="lectures-warning">You don't have any scores yet</h2>
		);

		return res;
	};

	renderTransactions = () => {
		let { profile } = this.props;
		let { chapters } = profile;
		console.log(profile, chapters)

		if (!chapters || !Object.keys(chapters).length) {
			return (
				<div className="dashboard-info-details">
					<img src={errorIcon} alt="check" />
					<h2>{"You didn't pay for any chapters yet"}</h2>
				</div>
			);
		}

		return Object.keys(chapters).map((chapterId, idx) => {
			const chapter = chapters[chapterId]
			const name = chapter.name;
			const paid = Number(chapter["paid"]);
			if (isNaN(paid)) {
				return <></>
			}

			return (
				<div key={idx} className="dashboard-info-details">
					{paid < 0 ? <img src={infoIcon} alt="info" /> : <img src={checkIcon} alt="check" />}
					<h2>
						{paid < 0 ?
							(
								<>
									You still need to pay (<span className="dashboard-info-amount">{Math.abs(paid)}</span>) for
								</>
							) : "Fees are paid for"} <span>{name}</span>
					</h2>
				</div>
			);
		});
	};

	render() {
		let { loading, exams } = this.state;

		let { profile, uid } = this.props;

		if (loading) {
			return (
				<div className="dashboard-wrapper">
					<Loader />
				</div>
			);
		}

		let { lastPayment } = profile;

		let isPaid = false;

		if (lastPayment) {
			let now = new Date();
			// console.log('now: ', now);
			let nowMonth = now.getMonth();
			let lastPaymentDate = new Date(lastPayment);
			// console.log('last: ', lastPaymentDate);
			let lastPaymentDateMonth = lastPaymentDate.getMonth();

			if (nowMonth <= lastPaymentDateMonth) {
				isPaid = true;
			}
		}

		return (
			<div className="dashboard-wrapper">
				<div className="dashboard-info">
					<div className="dashboard-personal">
						<div className="personal-image">
							<img
								src={profile.image + "?type=normal"}
								alt="profile"
							/>
							<h2>{profile.name}</h2>
						</div>
					</div>

					<div className="dashboard-info-item payments-info">
						{this.renderTransactions()}
					</div>

					{/*<div className="dashboard-info-item dashboard-table">
                        <iframe className="video-iframe" src="https://player.vimeo.com/video/443414420" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>*/}

					{/*<div className="dashboard-info-item">
                        <img src={monitorIcon} alt="monitor" />
                        <h2>{"You viewed 14 lectures"}</h2>
                    </div>*/}

					<div className="dashboard-info-item dashboard-table">
						{exams && exams.length !== 0 && (
							<div className="dashboard-table-item bold">
								<h3 className="transparent-text">exams</h3>
								<h3>Grade</h3>
								<h3>Notes</h3>
							</div>
						)}

						{this.renderExams()}
					</div>
				</div>

				<div className="dashboard-landing-image-wrapper">
					<div className="dashboard-landing-image">
						<QRCode
							style={{
								height: "auto",
								width: "calc(100% - 30px)",
								margin: "15px",
							}}
							value={uid}
						/>
						<h1 className="profile-code">{profile.code}</h1>
					</div>

					<div className="dashboard-landing-image">
						<a
							href="https://drive.google.com/file/d/1D0rvdUxHMX0LEmpwVtfLCrAblf5QEewy/view"
							target="_blank"
							rel="noopener noreferrer"
						>
							Discussion group
						</a>
						<a
							href="https://drive.google.com/file/d/17Km7cIJgY9VOdzGFwGK7jMtMjCWRiX5B/view"
							target="_blank"
							rel="noopener noreferrer"
						>
							Facebook page
						</a>
					</div>
				</div>
				{/*<img className="dashboard-landing-image" src={qrCode || qr} alt="QR code" />*/}
			</div>
		);
	}
}

export default Dashboard;
