import React, { Component } from "react";
import Loader from "../Loader/Loader";

import nextIcon from "../../assets/images/next.svg";
import taskIcon from "../../assets/images/task.svg";
import { API } from "../../Constants";

;

class Lecture extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lecture: {},
			loading: true,
		};
	}

	componentDidMount() {
		let { id } = this.props.match.params;

		let uid = window.localStorage.getItem("uid");
		let token = window.localStorage.getItem("token");

		if (!uid || !token) {
			window.location.pathname = "/login";
			return;
		}

		let lecture = {};
		// TODO: get lecture from API call instead
		fetch(`${API}/lecture/only/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"auth-token": token,
			},
		})
			.then((Response) => Response.json())
			.then((res) => {
				// console.log("lecture: ", res)
				if (res.accept) {
					lecture = res.data;
					this.setState({ lecture, loading: false });
				} else {
					alert("It seems your data is missing, please login again");
				}
			});
	}

	getLecture = () => {
		let { lecture } = this.state;

		if (lecture.type !== "vimeo") {
			return (
				<div className="lecture-body-container">
					<h1>{`This lecture is available at ${lecture.type}`}</h1>
					<button
						className="done-button"
						onClick={() => window.open(lecture.url)}
					>
						{"View Lecture"}
					</button>
				</div>
			);
		} else {
			return (
				<div
					className="lecture-body-container video-iframe"
					dangerouslySetInnerHTML={{ __html: lecture.url }}
				></div>
			);
		}
	};

	getResources = () => {
		let { lecture } = this.state;
		let { resources } = lecture;

		if (!resources || resources.length === 0) {
			return <h2>{"This lecture has no resources"}</h2>;
		}

		let res = resources.map((resource, idx) => (
			<a
				key={idx}
				href={resource.link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{resource.title}
			</a>
		));

		return res;
	};

	render() {
		let { lecture, loading } = this.state;

		let { id } = this.props.match.params;

		let { profile } = this.props;

		if (loading) {
			return (
				<div className="dashboard-wrapper">
					<Loader />
				</div>
			);
		}

		let hasAssignment = lecture.isHWRequired;

		return (
			<div className="dashboard-wrapper">
				<div className="lecture-title-container">
					<h1>{lecture.name}</h1>
				</div>

				<div className="lecture-assignment-container">
					{hasAssignment && (
						<button
							className="done-button assignment-button"
							onClick={() =>
								this.props.history.push(`/assignment/${id}`)
							}
						>
							<img src={taskIcon} alt="task" />
							{"Homework"}
							<img src={nextIcon} alt="arrow" />
						</button>
					)}
				</div>

				{this.getLecture()}

				<div className="lecture-resources-container">
					<h1>{"Resources"}</h1>
					{this.getResources()}
				</div>
			</div>
		);
	}
}

export default Lecture;
