import React, { useState } from 'react';

import logo from '../../assets/images/logo.png';
import { API } from '../../Constants';

const PhoneNumber = ({ history, profile }) => {
    const [number, setNumber] = useState(profile.number || '')
    const [parentNumber, setParentNumber] = useState(profile.parentNumber || '')

    let uid = window.localStorage.getItem('uid')
    let token = window.localStorage.getItem('token')

    if (!uid || !token) {
        window.location.pathname = '/login'
        return
    }

    const submitNumber = () => {
        if (!number) {
            alert('Please provide the student number');
            return
        }
        if (!parentNumber) {
            alert('Please provide the parent number');
            return
        }
        if (!number.match(/^\+[0-9]+$/)) {
            alert('The student phone number should only be numbers and starts with \'+\' followed by the country code ex. \'20\'');
            return
        }
        if (!parentNumber.match(/^\+[0-9]+$/)) {
            alert('The parent phone number should only be numbers and starts with \'+\' followed by the country code ex. \'20\'');
            return
        }
        if (number === parentNumber) {
            alert('The parent phone number should be different than the student phone number');
            return
        }
        fetch(`${API}/user/number/${uid}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            },
            body: JSON.stringify({
                number,
                parentNumber
            })
        }).then(Response => Response.json()).then(res => {
            if (res.accept) {
                window.location.pathname = '/'
            } else {
                alert(`${res.message || `An unknown error occured`}`)
            }
        }).catch(err => {
            console.log(err)
            alert(`Error please check your connection and try again`)
        })
    }

    return (
        <div className="login-wrapper">
            <img className="login-logo" src={logo} alt="EL Mahdy" />
            
            <div className="login-info-wrapper">
                <div className="login-number">
                    <span>Student Phone Number</span>
                    <input type='text' value={number} onChange={(e) => setNumber(e.target.value)} placeholder='example: +201112223334' />
                    <span>Parent Phone Number</span>
                    <input type='text' value={parentNumber} onChange={(e) => setParentNumber(e.target.value)} placeholder='example: +201112223334' />
                </div>
            </div>

            <div className="buttons-container login-buttons">
                <button className="done-button" onClick={submitNumber}>Submit</button>
            </div>
        </div>
    );
}
 
export default PhoneNumber;