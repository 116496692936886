import React from 'react';
import QRCode from 'qrcode.react';

import logo from '../../assets/images/logo.png';
import checkIcon from '../../assets/images/tick-gold.svg';

const SuccessfulLogin = ({ profile }) => {
    let uid = window.localStorage.getItem('uid')

    if (!uid) {
        window.location.pathname = '/login'
        return
    }

    return (
        <div className="login-wrapper successfult-login-wrapper">
            <img className="login-logo" src={logo} alt="EL Mahdy" />
            
            <div className="login-info-wrapper">
                <div className="login-info">
                    <div className="login-info-item">
                        <img src={checkIcon} alt="check" />
                        <h1>Congrats..</h1>
                    </div>

                    <h2>To proceed you should do the following:</h2>

                    <div className="login-steps-wrapper">
                        <h3><span className="login-number-container"><span className="login-number">1</span></span> Screenshot the QR code in your phone, you'll need it for attendence.</h3>
                        <h3><span className="login-number-container"><span className="login-number">2</span></span> Copy the code and <button className="done-button logout-button new-commer" onClick={() => window.open(`https://drive.google.com/file/d/1N9GmQ7LquPzng8T0iwkH-f9YjTMFi52w/view`)}>Complete the registration</button></h3>
                        <h3><span className="login-number-container"><span className="login-number">3</span></span> Receive an outstanding learning experience.</h3>
                    </div>
                </div>
                
                <div className="qr-image">
                    <QRCode style={{ height: "200px", width: "200px" }} value={uid} />
                    <h1 className="profile-code">{profile.code}</h1>
                </div>
            </div>
        </div>
    );
}
 
export default SuccessfulLogin;