import React, { Component } from 'react';

import nextIcon from '../../assets/images/next.svg';
import examIcon from '../../assets/images/exam.svg';
import meetingIcon from '../../assets/images/Meeting.svg';
import Loader from '../Loader/Loader';
import { API } from '../../Constants';



class ExamsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        // TODO: api call to get exams
        let uid = window.localStorage.getItem('uid')
        let token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }

        fetch(`${API}/exam/available`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            }
        }).then(Response => Response.json()).then(res => {
            if (res.accept) {
                // console.log('exams res: ', res)
                let exams = res.data

                this.setState({ exams, uid, loading: false })
            } else {
                alert(`${res.message || `An unknown error occured`}`)
                this.props.history.push('/login')
            }
        }).catch(err => {
            // console.log(err)
            alert('Error please check your connection and reload')
        })
    }

    renderExams = () => {
        let { exams } = this.state;
        let res = exams && exams.length ? (
            exams.map((exam, idx) => {
                let [style, image, url] = exam.isHidden ? ["disabled", examIcon, ""] : ["", examIcon, `/exam/${exam.examID}`]

                return (
                    <div key={idx} className={"lectures-item " + style} onClick={() => this.props.history.push(url)}>
                        <img src={image} alt="monitor" />
                        <h2>{exam.head}</h2>
                        <img src={nextIcon} alt="next" />
                    </div>
                )
            })
        ) : (
            <h1 className="lectures-warning">No exams yet</h1>
        )

        return res;
    }

    render() {
        let { loading } = this.state

        if (loading) {
            return (
                <div className="dashboard-wrapper">
                    <Loader />
                </div>
            )
        }

        return (
            <div className="dashboard-wrapper">
                <div className={"lectures-wrapper exams-wrapper"}>
                    <h1>Exams</h1>
                    {this.renderExams()}
                </div>

                <div className={"lectures-wrapper exams-wrapper exams-landing-image"}>
                    <img className="" src={meetingIcon} alt="exam" />
                </div>
            </div>
        );
    }
}
 
export default ExamsPage;