import { initializeApp } from '@firebase/app'
import { getStorage } from '@firebase/storage';
import { getFirestore } from '@firebase/firestore';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from '@firebase/auth';
// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyAltqOHrUIKA9HAxFOxjnfq037_f8kMf10",
  authDomain: "elmahdy-5a157.firebaseapp.com",
  databaseURL: "https://elmahdy-5a157.firebaseio.com",
  projectId: "elmahdy-5a157",
  storageBucket: "elmahdy-5a157.appspot.com",
  messagingSenderId: "283896875678",
  appId: "1:283896875678:web:8fbc31755b7a20cfcd098f",
  measurementId: "G-VSS39LBBRP"
};
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
// export const auth = firebase.
