import React, { Component } from 'react';
import { auth } from './firebase';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from '@firebase/auth';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import logo from '../../assets/images/logo.png';
import landing from '../../assets/images/landing.svg';
import google from '../../assets/images/google.png';

import './Login.css';
import { API } from '../../Constants';



class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    loginGmail = () => {
        var provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            'display': 'popup'
        })
        signInWithPopup(auth, provider).then(res => {
            //let token = res.credential.accessToken
            let user = res.user

            let { uid, email, displayName, photoURL } = user

            console.log("user to be logged in: ", user)

            // TODO: api call to sign user in
            fetch(`${API}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: uid,
                    name: displayName,
                    email,
                    image: photoURL,
                })
            }).then(Response => Response.json()).then(res => {
                if (res.accept) {
                    console.log("login res: ", res)
                    let { token } = res

                    window.localStorage.setItem('token', token)
                    window.localStorage.setItem('uid', uid)
                    window.location.pathname = '/'
                } else {
                    alert(`${res.message || `An unknown error occured`}`)
                }
            }).catch(err => {
                console.log(err)
                alert(`Error please check your connection and try again`)
            })

        }).catch(function (error) {
            // Handle Errors here.
            // console.log(error)
        });
    }

    loginFacebook = () => {
        // console.log("Logging in using facebook...")
        var provider = new FacebookAuthProvider();
        provider.setCustomParameters({
            'display': 'popup'
        })

        signInWithPopup(auth, provider).then(res => {
            //let token = res.credential.accessToken
            let user = res.user

            let { uid, email, displayName, photoURL } = user

            console.log("user to be logged in: ", user)

            // TODO: api call to sign user in
            fetch(`${API}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: uid,
                    name: displayName,
                    email,
                    image: photoURL,
                })
            }).then(Response => Response.json()).then(res => {
                if (res.accept) {
                    console.log("login res: ", res)
                    let { token } = res

                    window.localStorage.setItem('token', token)
                    window.localStorage.setItem('uid', uid)

                    let isShown = window.localStorage.getItem('isShown')

                    if (isShown) {
                        window.location.pathname = '/'
                    } else {
                        window.localStorage.setItem('isShown', true)
                        window.location.pathname = '/successful-login'
                    }
                } else {
                    alert(`${res.message || `An unknown error occured`}`)
                }
            }).catch(err => {
                console.log(err)
                alert(`Error please check your connection and try again`)
            })

        }).catch(function (error) {
            // Handle Errors here.
            // console.log(error)
        });

        /*let data = {}
        // console.log("Logging in using facebook: ", data)
        let { id, email, name, picture } = data

        let image = picture.data.url

        let user = {
            id, email, name, image
        }

        // console.log("user to be logged in: ", user)

        // TODO: api call to sign user in
        fetch(`${API}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id,
                name,
                email,
                image,
            })
        }).then(Response => Response.json()).then(res => {
            if (res.accept) {
                // console.log("login res: ", res)
                let { token } = res

                window.localStorage.setItem('token', token)
                window.localStorage.setItem('uid', id)

                window.location.pathname = '/successful-login'
            } else {
                alert(`Error please try again later`)
            }
        }).catch(err => {
            // console.log(err)
            alert(`Error please check your connection and try again`)
        })*/
    }

    render() {
        return (
            <div className="login-wrapper">
                <img className="login-logo" src={logo} alt="EL Mahdy" />

                <div className="login-info-wrapper">
                    <div className="login-info">
                        <h1>Welcome to ..</h1>
                        <h2>Mr. Ibrahim Elmahdy's Official Educational Website</h2>
                        <h3>Register now to be able to follow Mr ElMahdy's lectures, solve assignments and exams, measure and evaluate your progress and receive valuable guidance from Mr ElMahdy's assistant team</h3>
                    </div>

                    <img className="landing-image" src={landing} alt="landing" />
                </div>

                {/* <h4 className="login-button-label">Sign In Via</h4> */}
                {/*<FacebookLogin
                    appId="1585400961660093"
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={this.loginFacebook}
                    onClick={() => // console.log("clicked")}
                    render={renderProps => (
                        <button className="login-button" onClick={renderProps.onClick}>Facebook</button>
                    )}
                />*/}
                {/* <button className="login-button" onClick={this.loginFacebook}>Facebook</button> */}
                {<button className="login-button" onClick={this.loginGmail}><img src={google} alt='google' />Sign in with Google</button>}
            </div>
        );
    }
}

export default Login;