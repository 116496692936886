import React, { useState } from 'react';
import Loader from '../Loader/Loader';
import { RadioGroup, Radio } from 'react-radio-group';
import { withRouter } from 'react-router-dom';

import addIcon from '../../assets/images/add.svg';

const ExamForm = (props) => {
    let { isAssignment, exam, changeAnswer, submitExam } = props;

    let [file, setFile] = useState("")
    let [showFile, setShowFile] = useState(false)

    let getChoices = (choices) => {
        return choices.map((choice, idx) => {
            return (
                <label key={idx}>
                    <Radio value={choice.id} />{choice.body}
                </label>
            )
        })
    }

    let showQuestions = exam && exam.questions ? exam.questions.map((question, idx) => {
        let isChoice = question.type === "choices";

        if (!isChoice && !showFile) {
            setShowFile(true)
        }

        return (
            <div key={idx} className="exam-form-question">
                <h1>{(idx + 1) + ". " + question.head}</h1>

                {question.image && <img src={question.image} alt="question" />}

                {isChoice && (
                    <RadioGroup key={idx} className="exam-form-choices" name={question.head} onChange={(value) => changeAnswer(question.id, value)}>
                        {getChoices(question.choices)}
                    </RadioGroup>
                )}
            </div>
        )
    }) : (
        <Loader />
    )

    return (
        <div className="exam-form-wrapper">
            {showQuestions}

            {file && <h2 className="lectures-warning">{file.name}</h2>}
            <div className="buttons-container">
                <button className="done-button submit-button" onClick={() => submitExam(file)}>Submit</button>
                {!isAssignment && showFile && <label htmlFor="input-file-upload" className="done-button add-file-button">Add file <img src={addIcon} alt="add" /> </label>}
                {!isAssignment && showFile && <input id="input-file-upload" type="file" accept="application/pdf" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} />}
            </div>
        </div>
    );
}
 
export default withRouter(ExamForm);