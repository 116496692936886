import React, { Component } from 'react';
import { auth, storage } from '../Auth/firebase';

import './Exam.css';
import ExamForm from './ExamForm';
import Loader from '../Loader/Loader';
import { API } from '../../Constants';



class Exam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {},
            exam: {},
            loading: true,
        }
    }

    componentDidMount() {
        let { id } = this.props.match.params

        let uid = window.localStorage.getItem('uid')
        let token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }

        let exam = {}
        // TODO: get lecture from API call instead
        fetch(`${API}/exam/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            }
        }).then(Response => Response.json()).then(res => {
            console.log(res)
            if (res.accept) {
                // console.log("exam: ", res.data)
                exam = res.data
                this.setState({ exam, loading: false })
            } else {
                alert('It seems your data is missing, please login again')
            }
        })
    }

    // uploading the file to database
    uploadFile = async (uri) => {
        let user = auth.currentUser

        try {
            let imageRef = storage.ref("Submissions").child(`${user.uid}_${Date.now()}.pdf`);
            await imageRef.put(uri);

            // get the download link
            let url = await imageRef.getDownloadURL();
            return url;
        } catch (e) {
            // console.log(e);
        }
    }

    submitExam = async (file) => {
        // console.log("submitting exam...");

        this.setState({ loading: true })

        let { answers, exam } = this.state

        let { id } = this.props.match.params

        // console.log("answers to be submitted: ", answers);

        let answersArr = [];

        for (const key in answers) {
            if (answers.hasOwnProperty(key)) {
                const element = answers[key];
                answersArr.push({
                    id: key,
                    answer: element
                })
            }
        }

        let uid = window.localStorage.getItem('uid')
        let token = window.localStorage.getItem('token')

        if (!uid || !token) {
            window.location.pathname = '/login'
            return
        }

        let url = await this.uploadFile(file)

        /*console.log("answers: ", {
            answers: answersArr,
            id,
            link: url
        })*/

        fetch(`${API}/exam/submit/${id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            },
            body: JSON.stringify({
                answers: answersArr,
                id,
                link: url
            })
        }).then(Response => Response.json()).then(res => {
            // console.log('answers res: ', res)
            if (res.accept) {
                alert(`Your submission has been saved and under review`)
                window.location.pathname = '/'
            } else {
                alert(`${res.message || `An unknown error occured`}`)
                window.location.pathname = '/exams'
                this.setState({ loading: false })
            }
        }).catch(err => {
            // console.log(err)
            this.setState({ loading: false })
        })
    }

    changeAnswer = (questionId, value) => {
        let { answers } = this.state;

        answers[questionId] = value;

        // console.log("answers: ", answers);

        this.setState({ answers });
    }

    render() {
        let { exam, loading } = this.state;

        if (loading) {
            return (
                <div className="dashboard-wrapper assignment-wrapper">
                    <Loader />
                </div>
            )
        }

        return (
            <div className="dashboard-wrapper assignment-wrapper">
                <div className="lecture-title-container assignment">
                    <h1>{exam.head}</h1>
                </div>

                {/*<ExamForm isAssignment={false} exam={exam} changeAnswer={this.changeAnswer} submitExam={this.submitExam} />*/}
                <div className="exam-iframe" dangerouslySetInnerHTML={{ __html: exam.link }} />
            </div>
        );
    }
}

export default Exam;