import React from 'react';

import brandIcon from '../../assets/images/gold.png';

import './Loader.css';

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <img src={brandIcon} alt="ElMahdy" />
        </div>
    );
}
 
export default Loader;