import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { auth } from './components/Auth/firebase'
import './App.css';
import Login from './components/Auth/Login';
import PhoneNumber from './components/Auth/phoneNumber';
import Dashboard from './components/Dashboard/Dashboard';
import ExamsPage from './components/Exams/ExamsPage';
import Exam from './components/Exams/Exam';
import LecturesPage from './components/Lectures/LecturesPage';
import Lecture from './components/Lectures/Lecture';
import Assignment from './components/Lectures/Assignment';
import Navbar from './components/Navbar/Navbar';
import SuccessfulLogin from './components/Auth/SuccessfulLogin';
import Loader from './components/Loader/Loader';
import Announcements from './components/Announcements/Announcements';
import { API } from './Constants';

function App() {
	let [loading, setLoading] = useState(true);
	let [profile, setProfile] = useState(undefined);
	// const [user] = useAuthState(auth)

	let uid = window.localStorage.getItem('uid')
	let token = window.localStorage.getItem('token')

	const syncProfile = () => {
		const user = auth.currentUser;

		// AEcPtRqpJaXCkVLxUn4D for testing
		return fetch(`${API}/user/${user.uid}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'auth-token': token
			}
		})
	}

	useEffect(() => {
		if (!uid || !token) {
			auth.signOut()
			setProfile(undefined)
			setLoading(false)
			return
		}

		auth.onAuthStateChanged(user => {
			if (user) {
				if (uid !== user.uid) {
					auth.signOut()
					window.localStorage.removeItem('uid')
					window.localStorage.removeItem('token')

					uid = undefined
					token = undefined

					setProfile(undefined)
					setLoading(false)
					return
				}

				syncProfile().then(Response => Response.json()).then(res => {
					if (res.accept) {
						// console.log('user res: ', res)
						setProfile(res.data)
						setLoading(false)
						uid = window.localStorage.getItem('uid')
						token = window.localStorage.getItem('token')
					} else {
						alert(`${res.message || `An unknown error occured`}`)
						window.location.pathname = '/login'
					}
				}).catch(err => {
					// console.log(err)
				});
			} else {
				setProfile(undefined)
				setLoading(false)
			}
		})
	}, [])

	if (loading) {
		return (
			<div className="App">
				<Loader />
			</div>
		)
	}

	if (!profile || !uid || !token) {
		return (
			<BrowserRouter>
				<div className="App">
					<Switch>
						<Route path="/" render={(props) => <Login {...props} />} />
					</Switch>
				</div>
			</BrowserRouter>
		)
	}

	const { number, parentNumber, chapters } = profile;
	console.log("numbers: ", number)
	if (!number || !parentNumber) {
		return (
			<BrowserRouter>
				<div className="App">
					<Switch>
						<Route path="/" render={(props) => <PhoneNumber {...props} profile={profile} />} />
					</Switch>
				</div>
			</BrowserRouter>
		)
	}

	if (!chapters) {
		return (
			<BrowserRouter>
				<div className="App">
					<Switch>
						<Route path="/" render={(props) => <SuccessfulLogin {...props} profile={profile} />} />
					</Switch>
				</div>
			</BrowserRouter>
		)
	}

	return (
		<BrowserRouter>
			<div className="App">
				<Navbar />

				<Switch>
					<Route exact path="/lectures" render={(props) => <LecturesPage {...props} profile={profile} syncProfile={syncProfile} />} />
					<Route exact path="/lecture/:id" render={(props) => <Lecture {...props} profile={profile} />} />
					<Route exact path="/assignment/:id" render={(props) => <Assignment {...props} profile={profile} />} />
					<Route exact path="/exams" render={(props) => <ExamsPage {...props} profile={profile} />} />
					<Route exact path="/exam/:id" render={(props) => <Exam {...props} profile={profile} />} />
					<Route exact path="/announcements" render={(props) => <Announcements {...props} profile={profile} />} />
					<Route path="/" render={(props) => <Dashboard {...props} profile={profile} uid={uid} />} />
				</Switch>
			</div>
		</BrowserRouter>
	);
}

export default App;
